<template>
  <div>
    <!-- Hero -->
    <base-page-heading title="Utilizatori">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Utilizatori</b-breadcrumb-item>
          <b-breadcrumb-item active>Lista</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <!-- Page Content -->
    <div class="content">
      <base-block title="Lista de utilizatori" v-bind:subtitle="'Numar de utilizatori: '+userNumber">
        <b-table-simple responsive bordered striped table-class="table-vcenter">
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="d-sm-none" style="width: 80%;">Utilizator</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 25%;">Numele</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 20%;">Email</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 20%;">Sediu</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 15%">Acces la module </b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%">Status </b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%;"></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="user in users" :key="user._id">
              <b-td class="d-sm-none" style="width: 15%;">
                <div><span class="text-dark font-weight-bolder">{{ user.firstName }} {{ user.lastName }}</span></div>
                <div><span class="text-primary">{{ user.email }}</span></div>
                <b-row>
                  <b-col class="col-6 mt-2 text-left mt-1">
                    <span v-bind:class="{'text-danger': !user.active, 'text-success': user.active}" class="text-primary font-w600">
                      {{ user.active ? 'Activ' : 'Inactiv' }}
                    </span>
                  </b-col>
                  <b-col class="col-6 mt-2 text-right">
                    <router-link v-if="!user.admin" class="align-items-center justify-content-between btn btn-alt-primary btn-sm"  v-bind:to="getDetailUrl(user._id)">
                      <i class="fa fa-fw fa-pencil-alt"></i> editare
                    </router-link>
                  </b-col>
                </b-row>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-w600">
                <span class="text-dark font-weight-bolder">{{ user.firstName }} {{ user.lastName }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell">
                <span class="text-primary">{{ user.email }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell text-center">
                <span class="text-primary font-w600">{{ user.companyHeadquarter.name }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell text-center" align="center">
                <ul class="small mb-0">
                  <li class="text-left" v-for="enabledModule in user.userRole.enabledModuleNameList" :key="enabledModule">
                    {{ enabledModule }}
                  </li>
                </ul>
              </b-td>
              <b-td class="d-none d-sm-table-cell text-center" align="center">
                <span v-bind:class="{'text-danger': !user.active, 'text-success': user.active}" class="text-primary font-w600">
                  {{ user.active ? 'Activ' : 'Inactiv' }}
                </span>
              </b-td>
              <b-td class="d-none d-sm-table-cell text-center border-left-0">
                <router-link v-if="!user.admin" class="align-items-center justify-content-between btn btn-alt-primary btn-md" v-bind:to="getDetailUrl(user._id)">
                  <i class="fa fa-fw fa-pencil-alt"></i> editare
                </router-link>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </base-block>
    </div>
  </div>
</template>
<script>
import {USER_LIST} from "../../../constants/user-graphql";

export default {
  name: "UserList",
  data () {
    return {
      users: [],
      loaded: false,
      userNumber: 0,
    }
  },
  methods: {
    getDetailUrl(id)
    {
      return '/user/edit/'+btoa(encodeURI(id));
    }
  },
  apollo: {
    meUser: {
      query: USER_LIST,
      fetchPolicy: 'no-cache',
      result(result) {
        this.users = result.data.meUser.company.user;
        this.loaded = true;
        this.userNumber = this.users.length;
      }
    }
  },
}
</script>